<template>
  <BaseModal
    :has-logo="true"
    content-transition="vfm-slide-up"
    :to-bottom="!xlAndLarger"
    :min-height="90"
    class-name="min-h-[90vh] sm:min-h-[auto]"
    @closed="emit('closed')"
  >
    <div :class="classes.filtered">
      <BaseSearchInput
        v-model="searchText"
        class="mb-[15px]"
        placeholder="Поиск..."
        icon-align="left"
      />
      <div v-if="filteredCities.length" :class="classes.columns">
        <div v-for="(column, index) in columns" :key="'column' + index">
          <div
            v-for="(group, letter) in groupCitiesByLetter(column)"
            :key="'group' + letter"
            :class="classes.group"
          >
            <span :class="classes.letter">
              {{ letter }}
            </span>
            <a
              v-for="(city, cityIndex) in group"
              :key="'city' + cityIndex"
              href="#"
              :class="classes.city"
              @click.prevent="changeCity(city)"
            >
              {{ city.name }}
            </a>
          </div>
        </div>
      </div>
      <div v-else>
        Ничего не найдено
      </div>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { cx } from 'cva'
import { storeToRefs } from 'pinia'
import groupBy from 'lodash/groupBy'
import type { City } from '~/types/models'

const emit = defineEmits<{
  (e: 'changeCity' | 'closed'): void
}>()

const classes = {
  group: cx(['relative', 'grid', 'gap-[3px]', 'mb-5', 'last:mb-0']),
  letter: cx(['absolute', 'text-[18px]/[27px]', 'font-bold', 'text-yellow']),
  city: cx([
    'pl-[25px]',
    'text-[18px]/[27px]',
    'font-medium',
    'hover:text-accent-hover',
    'active:text-accent-click',
    'hover:font-semibold',
    'transition',
    'duration-300',
  ]),
  columns: cx([
    'grid',
    'grid-cols-1',
    'xl:grid-cols-3',
    'sm:max-h-[450px]',
    'overflow-y-scroll',
  ]),
  filtered: cx([
    'min-w-full',
    'p-4',
    'xl:min-w-[820px]',
    'lg-xl:min-w-[1016px]',
    'xl:pr-[30px]',
    'pb-5',
  ]),
}

const citiesStore = useCitiesStore()
const { list: cities } = storeToRefs(citiesStore)

const viewport = useViewport()
const xlAndLarger = computed(() => viewport.isGreaterOrEquals('xl'))

const searchText = ref('')

const groupCitiesByLetter = (cities: City[]) => {
  return groupBy(cities, (city: City) => {
    return city.name.charAt(0)
  })
}

const filteredCities = computed((): City[] => {
  if (!cities) {
    return []
  }

  return cities.value.filter((city: City) => {
    return city.name
      .toLowerCase()
      .startsWith(searchText.value.toLowerCase().trimEnd())
  })
})

const columns = computed(() => {
  if (!filteredCities.value) {
    return
  }

  return splitToChunks(filteredCities.value, 3)
})

const changeCity = (city: City) => {
  searchText.value = ''
  citiesStore.changeCurrent(city.name)
  emit('changeCity')
}
</script>