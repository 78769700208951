<template>
  <form class="relative" @submit.prevent="search">
    <BaseInputText
      v-model="searchText"
      intent="primary"
      :type="'search'"
      :class-name="
        cx(classes.input, iconAlign === 'right' ? 'pr-[61px]' : 'pl-[40px]')
      "
      :minlength="4"
      :placeholder="placeholder"
    />
    <div
      :class="[
        classes.icon,
        iconAlign === 'right' ? 'right-[25px]' : 'left-[8px]',
      ]"
    >
      <Icon
        name="common:search"
        size="22"
        class="text-ghost"
        @click="search"
      />
      <Icon
        v-if="closable"
        name="common:cross"
        size="22"
        class="text-ghost"
        @click="onClose"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { cx } from 'cva'
import mixpanel from 'mixpanel-browser'
import { ROUTE_NAMES } from '@/constants'

defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  iconAlign: {
    type: String,
    default: 'right', // or "left"
  },
  closable: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits<{
  'update:modelValue': [payload: string]
  'close': [payload: string]
}>()

const classes = {
  input: cx(['min-w-full', 'min-h-[44px]']),
  icon: cx(['absolute', 'top-2.5', '!text-icon-disable', 'cursor-pointer']),
}

const searchText = ref('')

const onClose = () => {
  searchText.value = ''
  emits('close', '')
}

watch(searchText, (newSearchText) => {
  emits('update:modelValue', newSearchText)
})

const search = () => {
  emitYandexMetrika(YANDEX_METRIKA_GOALS.POISK_HEADER)
  mixpanel.track('search', {
    query: searchText.value,
  })

  navigateTo({
    name: ROUTE_NAMES.SEARCH,
    params: {
      searchQuery: searchText.value,
    },
  })
}
</script>